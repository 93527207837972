import { FileInterface, Grant } from '@hydra/interfaces'
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react'
import { AxiosResponse } from 'axios'
import { clsx } from 'clsx'
import { useCallback, useMemo, useState } from 'react'
import { BsImageFill } from 'react-icons/bs'
import { HiDocument, HiVideoCamera } from 'react-icons/hi2'
import { TbDotsVertical, TbHeadphonesFilled } from 'react-icons/tb'
import ReactPlayer from 'react-player/lazy'
import { toast } from 'react-toastify'
import Lightbox from 'yet-another-react-lightbox'

import { deleteFileFromS3 } from '../../api'
import {
  audioExtensions,
  docExtensions,
  imageExtensions,
  videoExtensions,
} from '../../constants/fileExtensions.const'
import { useGrant } from '../../grants'
import { createLinkAndDownloadFile } from '../../utils/createLinkAndDownloadFile'
import { getMimeType } from '../../utils/getMimeType'
import { DeleteModal } from './DeleteModal'

interface Props {
  file: FileInterface
  refetch: () => void
  deleteFn: (value: string) => Promise<AxiosResponse<void>>
}

export const FileItemPreview = ({ file, refetch, deleteFn }: Props) => {
  const { isValidGrant: isEditor } = useGrant(Grant.newsbreaksEditor)
  const [isOpenLightBox, setIsOpenLightBox] = useState(false)

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onOpenChange: onDeleteOpenChange,
    onClose: onDeleteClose,
  } = useDisclosure()

  const {
    isOpen: isViewVideoOpen,
    onOpen: onViewVideoOpen,
    onOpenChange: onViewVideoOpenChange,
  } = useDisclosure()

  const isImage = useMemo(
    () => Object.keys(imageExtensions).includes(getMimeType(file.link)),
    [file.link],
  )

  const isVideo = useMemo(
    () => Object.keys(videoExtensions).includes(getMimeType(file.link)),
    [file.link],
  )

  const isDoc = useMemo(
    () => Object.keys(docExtensions).includes(getMimeType(file.link)),
    [file.link],
  )

  const isAudio = useMemo(
    () => Object.keys(audioExtensions).includes(getMimeType(file.link)),
    [file.link],
  )

  const disabledKeys = useMemo(() => {
    const keys = []
    if (isEditor) keys.push('delete')
    if (isAudio || isDoc) keys.push('view')
    return keys
  }, [isAudio, isDoc, isEditor])

  const handleViewFile = useCallback(() => {
    if (isImage) {
      setIsOpenLightBox(true)
    }

    if (isVideo) {
      onViewVideoOpen()
    }
  }, [isImage, isVideo, onViewVideoOpen])

  const handleDeleteFile = useCallback(async () => {
    try {
      const response = await deleteFn(file.id)

      if (response.status && response.status === 200) {
        await deleteFileFromS3(file.title, import.meta.env.VITE_BUCKET_NAME)
        toast.success('Файл успешно удален')
        await refetch()
        onDeleteClose()
      }
    } catch (e) {
      console.error(e)
    }
  }, [file.id, file.title, onDeleteClose, refetch])

  return (
    <>
      <DeleteModal
        isOpen={isDeleteOpen}
        onOpenChange={onDeleteOpenChange}
        deleteAction={handleDeleteFile}
        onClose={onDeleteClose}
      />

      <Lightbox
        open={isOpenLightBox}
        close={() => setIsOpenLightBox(false)}
        slides={[{ src: file.link }]}
      />

      <Modal
        isOpen={isViewVideoOpen}
        onOpenChange={onViewVideoOpenChange}
        size='2xl'
      >
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader>Просмотр видео</ModalHeader>
              <ModalBody className='flex items-center justify-center'>
                <ReactPlayer
                  controls
                  url={file.link}
                  width={500}
                  height={282}
                />
              </ModalBody>
              <ModalFooter>
                <Button color='danger' variant='light' onPress={onClose}>
                  Закрыть
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <div
        className={clsx(
          'bg-background border-default-200 flex items-center space-x-4 rounded-xl border p-3 transition-shadow',
          {
            'cursor-pointer hover:shadow': isImage || isVideo,
          },
        )}
        onClick={handleViewFile}
      >
        <div className='flex-0 bg-primary-50 text-primary flex h-10 w-10 items-center justify-center rounded-lg'>
          {isAudio && <TbHeadphonesFilled className='text-2xl' />}
          {isVideo && <HiVideoCamera className='text-2xl' />}
          {isImage && <BsImageFill className='text-2xl' />}
          {isDoc && <HiDocument className='text-2xl' />}
        </div>

        <div className='line-clamp-1 flex-1'>{file.title}</div>

        <div>
          <Dropdown>
            <DropdownTrigger>
              <Button size='sm' isIconOnly variant='light'>
                <TbDotsVertical />
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              aria-label='Меню выбора действий с файлом'
              disabledKeys={disabledKeys}
            >
              <DropdownItem key='view' onPress={handleViewFile}>
                Просмотреть
              </DropdownItem>
              <DropdownItem
                key='download'
                onPress={() => {
                  createLinkAndDownloadFile(file.link, file.title)
                }}
              >
                Скачать
              </DropdownItem>
              <DropdownItem key='delete' onPress={onDeleteOpen}>
                Удалить
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </>
  )
}
