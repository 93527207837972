import {
  LogInterface,
  PublicInterface,
  RiskImportanceInterface,
  RiskInterface,
  RiskStatusEnum,
  RiskThemeInterface,
  RiskTypeInterface,
} from '@hydra/interfaces'
import { TElement } from '@udecode/plate-common'
import { AxiosResponse } from 'axios'
import { SortDirection } from 'typeorm'

import { instance } from '../../../features/api'

export const createRisk = (data: {
  title: string
  isPlan?: boolean
  date?: string
  description?: Array<TElement>
  address?: string
  chat?: {
    id: string
  }
  theme?: {
    id: string
  }
  type?: {
    id: string
  }
  files: Array<{ id: string }> | null
}): Promise<AxiosResponse<string>> => {
  return instance.post('/risks/create-risk', data)
}

export const getAllRisks = async (params: {
  skip: number
  take: number
  direction: SortDirection
  order: keyof PublicInterface
  status: RiskStatusEnum
  searchString?: string
  type?: { id: string }
  theme?: { id: string }
  importance?: { id: string }
  location?: { id: string }
  address?: string
}): Promise<AxiosResponse<[Array<RiskInterface>, number]>> => {
  return instance.get('/risks/all-risks', { params })
}

export const getAllRisksByDate = (params: {
  dayStart: Date
  dayEnd: Date
}): Promise<AxiosResponse<Array<RiskInterface>>> => {
  return instance.get('/risks/all-by-date', { params })
}

export const getAllRiskForPosts = async (): Promise<
  AxiosResponse<[Array<RiskInterface>, number]>
> => {
  return instance.get('/risks/all-for-posts')
}

export const getRiskById = (
  id: string,
): Promise<AxiosResponse<RiskInterface>> => {
  return instance.get(`/risks/risk-by-id/${id}`, {})
}

export const updateRisk = (
  id: string,
  data: {
    description?: Array<TElement>
    status?: RiskStatusEnum
    title?: string
    isPlan?: boolean
    date?: string
    address?: string
    location?: {
      id: string
    }
    theme?: {
      id: string
    }
    type?: {
      id: string
    }
    importance?: {
      id: string
    }
    chat?: {
      id: string
    }
  },
): Promise<AxiosResponse<string>> => {
  return instance.patch(`/risks/update-risk/${id}`, data)
}

export const getRiskThemes = async (): Promise<
  AxiosResponse<[RiskThemeInterface]>
> => {
  return instance.get('/risks/themes')
}

export const getRiskTypes = async (): Promise<
  AxiosResponse<[RiskTypeInterface]>
> => {
  return instance.get('/risks/types')
}

export const getRiskImportances = async (): Promise<
  AxiosResponse<[RiskImportanceInterface]>
> => {
  return instance.get('/risks/importances')
}

export const createRiskFile = (data: {
  title: string
  link: string
  riskId?: string
}): Promise<AxiosResponse<string>> => {
  return instance.post('/risks/create-risk-file', data)
}

export const deleteRiskFile = (id: string) => {
  return instance.delete(`/risks/delete-risk-file/${id}`)
}

export const createRiskComment = (data: { value: string; riskId: string }) => {
  return instance.post('/risks/create-risk-comment', data)
}

export const getAllLogs = (
  riskId: string,
): Promise<AxiosResponse<Array<LogInterface>>> => {
  return instance.get(`/risks/all-logs/${riskId}`)
}
