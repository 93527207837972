import { PublicInterface, RiskStatusEnum } from '@hydra/interfaces'
import { atomWithQuery } from 'jotai-tanstack-query'
import { atom } from 'jotai/vanilla'
import { atomWithStorage } from 'jotai/vanilla/utils'

import { extendedDayjs } from '../../../features/utils/dayjsConfig'
import {
  getAllLogs,
  getAllRiskForPosts,
  getAllRisks,
  getAllRisksByDate,
  getRiskById,
  getRiskImportances,
  getRiskThemes,
  getRiskTypes,
} from '../api/risk.api'

export const skipAtom = atom(0)
export const takeAtom = atom(25)
export const orderAtom = atom<keyof PublicInterface>('createdAt')
export const directionAtom = atom<'desc' | 'asc'>('desc')
export const riskIdAtom = atom<string | undefined>(undefined)
export const statusTabAtom = atom<RiskStatusEnum>(RiskStatusEnum.draft)
export const searchStringAtom = atom<string | undefined>(undefined)
export const locationFilterAtom = atom<string | undefined>(undefined)
export const addressFilterAtom = atom<string | undefined>(undefined)
export const typeFilterAtom = atom<string | undefined>(undefined)
export const themeFilterAtom = atom<string | undefined>(undefined)
export const importanceFilterAtom = atom<string | undefined>(undefined)

export const allRisksAtom = atomWithQuery(get => {
  return {
    queryKey: ['all-risks-list'],
    queryFn: async () => {
      const response = await getAllRiskForPosts()
      return response.data
    },
  }
})

export const risksAtom = atomWithQuery(get => {
  return {
    queryKey: [
      'risks-list',
      get(skipAtom),
      get(takeAtom),
      get(orderAtom),
      get(directionAtom),
      get(statusTabAtom),
      get(searchStringAtom),
      get(locationFilterAtom),
      get(addressFilterAtom),
      get(typeFilterAtom),
      get(themeFilterAtom),
      get(importanceFilterAtom),
    ],
    queryFn: async () => {
      const response = await getAllRisks({
        skip: get(skipAtom),
        take: get(takeAtom),
        order: get(orderAtom),
        direction: get(directionAtom),
        status: get(statusTabAtom),
        searchString: get(searchStringAtom),
        location: get(locationFilterAtom)
          ? { id: get(locationFilterAtom) || '' }
          : undefined,
        address: get(addressFilterAtom),
        type: get(typeFilterAtom)
          ? { id: get(typeFilterAtom) || '' }
          : undefined,
        theme: get(themeFilterAtom)
          ? { id: get(themeFilterAtom) || '' }
          : undefined,
        importance: get(importanceFilterAtom)
          ? { id: get(importanceFilterAtom) || '' }
          : undefined,
      })
      return response.data
    },
  }
})

export const riskAtom = atomWithQuery(get => {
  return {
    enabled: !!get(riskIdAtom),
    queryKey: ['risk-by-id', get(riskIdAtom)],
    queryFn: async () => {
      const response = await getRiskById(get(riskIdAtom) || '')
      return response.data
    },
  }
})

export const riskThemesAtom = atomWithQuery(() => {
  return {
    queryKey: ['risk-themes'],
    queryFn: async () => {
      const response = await getRiskThemes()
      return response.data
    },
  }
})

export const riskTypesAtom = atomWithQuery(() => {
  return {
    queryKey: ['risk-types'],
    queryFn: async () => {
      const response = await getRiskTypes()
      return response.data
    },
  }
})

export const riskImportancesAtom = atomWithQuery(() => {
  return {
    queryKey: ['risk-importances'],
    queryFn: async () => {
      const response = await getRiskImportances()
      return response.data
    },
  }
})

export const calendarYearAtom = atom(extendedDayjs().year())
export const calendarMonthAtom = atom(extendedDayjs().month())
export const calendarWeekAtom = atom(extendedDayjs().week())
export const calendarDayAtom = atom(extendedDayjs().date())
export const calendarViewAtom = atomWithStorage<'month' | 'week'>(
  'calendarView',
  'week',
)

export const risksByWeekAtom = atomWithQuery(get => {
  return {
    enabled: get(calendarViewAtom) === 'week',
    queryKey: [
      'newsbreaks-by-week',
      get(calendarYearAtom),
      get(calendarMonthAtom),
      get(calendarWeekAtom),
    ],
    queryFn: async () => {
      const currentMonth = extendedDayjs()
        .set('year', get(calendarYearAtom))
        .set('month', get(calendarMonthAtom))

      const response = await getAllRisksByDate({
        dayStart: currentMonth
          .startOf('week')
          .week(get(calendarWeekAtom))
          .toDate(),
        dayEnd: currentMonth.endOf('week').week(get(calendarWeekAtom)).toDate(),
      })

      return response.data
    },
  }
})

export const risksByMonthAtom = atomWithQuery(get => {
  return {
    queryKey: [
      'newsbreaks-by-month',
      get(calendarYearAtom),
      get(calendarMonthAtom),
    ],
    queryFn: async () => {
      const currentMonth = extendedDayjs()
        .set('year', get(calendarYearAtom))
        .set('month', get(calendarMonthAtom))

      const response = await getAllRisksByDate({
        dayStart: currentMonth.startOf('month').subtract(1, 'month').toDate(),
        dayEnd: currentMonth.endOf('month').add(1, 'month').toDate(),
      })

      return response.data
    },
  }
})

export const allLogsAtom = atomWithQuery(get => {
  return {
    enabled: !!get(riskIdAtom),
    queryKey: ['risk-log', get(riskIdAtom)],
    queryFn: async () => {
      const response = await getAllLogs(get(riskIdAtom) ?? '')
      return response.data
    },
  }
})
